@import 'config.scss';
@import '@selfaware/wool/src/index.scss';
$splitPea: #92C83E;
$orange:#F6871F;
$white: #FFFEF9;
$black: #231F20;
$red: #E93127;

@font-face {
    font-family: 'Decibel';
    src: url(Decibel/DecibelLoudest.otf) format('opentype');
}
@font-face {
    font-family: 'Gig';
    src: url(Gig/GigBold.otf) format('opentype');
}
@font-face {
    font-family: 'Motter';
    src: url(Motter/Normal.ttf) format('truetype');
}

@font-face {
    font-family: 'Mabry';
    font-weight: 200;
    src: url(Mabry/MabryProLight.otf) format('opentype');
}
@font-face {
    font-family: 'Mabry';
    font-weight: 800;
    src: url(Mabry/MabryProBlack.otf) format('opentype');
}


@font-face {
    font-family: 'Syne';
    font-weight: 200;
    src: url(Syne/Syne-Bold.otf) format('opentype');
}
@font-face {
    font-family: 'Syne';
    font-weight: 800;
    src: url(Syne/Syne-Extra.otf) format('opentype');
}
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}
canvas:active, canvas:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}
.r30 {
  transform: rotate(20deg);
}
.about-button {
  cursor: pointer;
  background-color: $white;
  box-shadow: 0px 0px 0px $splitPea;
  transform: translateY(0) scale(1) rotate(0deg);
  transition: transform 400ms ease, box-shadow 800ms ease; 
}
.about-button-mobile {

}
.about-button:hover {
  background-color: $splitPea;
  box-shadow: 0px 10px 8px $splitPea;
  transform: translateY(1) scale(1.05) rotate(20deg);
  transition: transform 400ms ease, box-shadow 800ms ease; 
}
.about-modal {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  height: 100vh;
  z-index: 10;
  top: 0rem;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  display: flex;
  justify-content: center;
  align-items:center;
  transform: translateY(0);
  opacity: 1;
  background-color: $white;
  pointer-events: auto;
  transition: all 800ms ease;
}
.about-modal-closed {
  font-size: 0rem;
  position: fixed;
  top: 0rem;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  display: flex;
  justify-content: center;
  align-items:center;
  z-index: 10;
  transform: translateY(-100px);
  opacity: 0;
  pointer-events: none;
  transition: all 800ms ease;

}
.about-modal-inner {
  font-size: 3.2rem;
  height: 100%;
  width: 100%;
  border: 1px solid $black;
  border-radius: 0px;
  background-color: $splitPea;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items:center;
  flex-direction: column;
  color: $white;
  padding: 0rem 3rem;
  
  font-family: Syne;
  box-shadow: 0px 10px 8px $splitPea;
  background-image: url('./stars.gif');
}
.about-modal-inner-mobile {
  border-radius: 0;
  height: 100%;
  width: 100%;
  padding-top: 10rem;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
a {
  color: $white !important;
  text-decoration: none !important;
  height: 100%;
  width: 100%;
}
.about-modal-x {
  justify-self: start;
  align-self: flex-end;
  margin-top: 3rem;
  font-size: 3.2rem;
  border: 1px solid $white;
  padding: 2px 12px;
  font-weight: 200;
  cursor: pointer;
  border-radius:8px;
  
  transition: all 400ms ease; 
}
.about-modal-x:hover {
  background-color: $white;
  color: $black;
  transition: all 400ms ease; 
}
.about-modal-x:active {
  background-color: $white;
  color: $black;
  transition: all 400ms ease; 
}
.about-modal-x-mobile {
  font-size: 3.2rem;
  border: 2px solid $white;
  padding: 6px 24px;
  
}
.about-modal-content {
  height: 100%;
  width: 100%;
  padding: 3rem;
  justify-self: center;
  align-self: center;
}
.cloud {
  background-image: url('./cloud-loop.gif');
  opacity: 1;
}
.cloud-mobile {
  background-size: 1000px auto;
  background-image: url('./cloud-loop.gif');
  opacity: 1;
}

.email-inner
{
    position: relative;
    cursor: pointer;
    text-align: center;
}
.email {
  transition: all 0.3s ease-in-out;
}
.email:hover {
  padding: 5px;
  transition: all 0.3s ease-in-out;
}

.email-inner:before {
  content: "";
  position: absolute;
  opacity: 1;
  padding: 5px 20px;
  border-radius: 30px;
  width: 0;
  height: 20px;
  border: 3px solid $white;
  bottom: -5px;
  left: -20px;
  
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.email-inner:hover:before {
  visibility: visible;
  width: 100%;
}
.email-inner:after {
  content: "";
  position: absolute;
  border-radius: 30px;
  font-size: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  opacity: 1;
  padding: 5px 5px;
  padding-top: 10px;
  background-color: $splitPea;
  color: $white;
  width: 0;
  height: 10px;
  border: 3px solid $splitPea;
  top: -25px;
  right: -40px;
  
  visibility: hidden;
  transition: all 300ms ease-in-out;
  
}
.email-inner:hover:after {
  content: "click to copy";
  font-size: 1rem;
  visibility: visible;
  width: 100px;
}

.email-inner-clicked:after {
  content: "";
  position: absolute;
  border-radius: 30px;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  opacity: 1;
  padding: 5px 5px;
  padding-top: 10px;
  background-color: $splitPea;
  color: $white;
  width: 0;
  height: 10px;
  border: 3px solid $splitPea;
  top: -25px;
  right: -40px;
  
  visibility: hidden;
  transition: width 300ms ease-in-out,
  visibility 300ms ease-in-out,
  content 300ms ease-in-out 300ms,;
  
}
.email-inner-clicked:hover:after {
  content: "copied!";
  visibility: visible;
  width: 100px;
}
.menu-title-active {
  transition-duration: 300ms;
  background-color: $splitPea;
  color: $white;
  font-size: 3.2rem;
}
.menu-title-neutral {
  opacity: 1;
  transition-duration: 300ms;
}
.menu-title-inactive {
  opacity: 0;
  transition-duration: 300ms;
}
.click-area {
  color: $black !important;
  height: 100%;
  width: 50%;
  pointer-events: auto;
  z-index: 8;
}
.open {
  width: 35%;
  transition-duration: 300ms;
}
.closed {
  width: 2%;
  transition-duration: 300ms;
}
.section-right {
  width: 52%;
  position: absolute;
}
.section-right-mobile {

}
.open-text-x {
  cursor: pointer;
  opacity: 1;
  background-color: $white;
  transform: translateY(0);
  transition: transform 500ms ease 400ms,
  opacity 900ms ease 300ms,
  background-color 300ms ease 0ms;
}
.open-text-x:hover{
  background-color: $splitPea;
}

.closed-text-x {
  pointer-events: none;
  opacity: 0;
  transform: translateY(-300px);
  transition: transform 200ms ease 0ms,
  opacity 100ms ease 0ms;
}
.open-text-title {
  opacity: 1;
  transform: translateY(0);
  transition: transform 500ms ease 400ms,
  opacity 900ms ease 300ms;
}
.closed-text-title {
  pointer-events: none;
  opacity: 0;
  transform: translateY(-100px);
  transition: transform 200ms ease 0ms,
  opacity 100ms ease 0ms;
}
.open-text-desc {
  opacity: 1;
  transform: translateY(0);
  transition: transform 700ms ease 600ms,
  opacity 700ms ease 500ms;
}
.closed-text-desc {
  pointer-events: none;
  opacity: 0;
  transform: translateY(-50px);
  transition: transform 200ms ease 0ms,
  opacity 100ms ease 0ms;
}
.form-active {

  opacity: 1;
  transition: all 1000ms ease 0ms;
}
.form-inactive {
 
  opacity: 0;
  transition: all 1000ms ease 0ms;
}
.yourein-active {
  text-align: center;
   opacity: 1;
  transition: all 1000ms ease 800ms;
}
.yourein-inactive {
  opacity: 0;
  transition: all 1000ms ease 800ms;

}
.ticket-canvas {
  height: 300px !important;
}
.ticket-canvas-mobile {
  height: 200px !important;
}
.canvas-mobile {
  height: 35vh !important;
}

.form-element {
  height: 30px;
  background-color: $white;
  border: 1px solid $black;
  border-radius: 20px;
  padding-left: 10px;
  font-family: 'Mabry';
  transform: scale(1);
  box-shadow: 0px 0px 0px $splitPea;
  transition: transform 400ms ease, box-shadow 800ms ease; 
}
.form-element-mobile {
  padding-left: 10px;
  margin-left: -10px;

}
.form-element:active,.form-element:focus {
  background-color: $splitPea;
  color: $black;
  box-shadow: 0px 10px 8px $splitPea;
  transform: scale(1.05);
  transition: transform 400ms ease, box-shadow 800ms ease; 
}
.form-element:active, .form-element:focus {
  outline: 0;
  -moz-outline-style: none;
}
.email-registered-label {
  color: $red;
}
.email-registered-input {
  border: 1px solid $red;
  animation: shake 1s;

}
.submit-button {
  border: 1px solid $black;
  border-radius: 30px;
  color: $white;
  font-weight: 800;
  font-size: 1.6rem;
  padding: 10px 20px;
  box-shadow: 0px 0px 0px $splitPea;
  background-color: $splitPea !important;
  background: $splitPea !important;
   -webkit-appearance: none;
  font-family: 'Mabry';
  transform: scale(1);
  transition: transform 200ms ease, box-shadow 400ms ease;  
}
.submit-button:hover {
  box-shadow: 0px 10px 8px $splitPea;
  transform: scale(1.05);
  transition: transform 200ms ease, box-shadow 400ms ease;  
}

.submit-button:focus {
  outline: 0 !important;
  -moz-outline-style: none;
}
.socials {
  height: 5rem;
}
.social-link {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
}
.social {
  filter: blur(0);

  
  transition-duration: 300ms;
}
.social:hover {
  filter: blur(2px);
  transition-duration: 300ms;
}


.App-logo {
  
  font-size: 12rem;
  pointer-events: none;

}
.App-note {
  font-family: 'Gig';
  font-size: 4rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  font-family: 'Decibel';

  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  color: black;
}

.App-link {
  color: #61dafb;
}
/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

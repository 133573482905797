$font-stacks: (
  'sans': "'Helvetica', sans-serif",
  'serif': "'Times New Roman', serif",
  'motter': "'Motter', sans-serif",
  'decibel': "'decibel', sans-serif",
  'gig': "'Gig', sans-serif",
  'mabry': "'Mabry', sans-serif",
  'syne': "'Syne', sans-serif"
) !default;

$colors: (
  'black': #231F20,
  'white': #FFFEF9,
  'green': #69BE5A,
  'light-green': #85CA9B,
  'split-pea':#92C83E,
  'orange':#F6871F,
  'grey1': #333132,
  'grey2': #434345,
  'grey3': #545456,
  'grey4': #5C5D60,
) !default;

$font-sizes: (
  '0': 0rem,
  '8': 0.8rem,
  '16': 1.6rem,
  '20': 2rem,
  '24': 2.4rem,
  '32': 3.2rem,
  '56': 5.6rem,
  '64': 6.4rem,
  '100': 10rem
) !default;


$border-widths: (
  '1': 1px,
  '2': 2px
) !default;

$border-radiuses: (
  '5': 5px,
  '10': 10px,
  '50': 50%
) !default;

$sizing: (
  'x': (
    width: 100%
  ),
  'y': (
    height: 100%
  ),
  'w1': (
    width: 1%
  ),
  'w5': (
    width: 5%
  ),
  'w10': (
    width: 10%
  ),
  'w15': (
    width: 15%
  ),
  'w20': (
    width: 20%
  ),
  'w25': (
    width: 25%
  ),
  'w30': (
    width: 30%
  ),
  'w35': (
    width: 35%
  ),
  'w40': (
    width: 40%
  ),
  'w45': (
    width: 45%
  ),
  'w50': (
    width: 50%
  ),
  'w55': (
    width: 55%
  ),
  'w56': (
    width: 56%
  ),
  'w48': (
    width: 48%
  ),
  'w60': (
    width: 60%
  ),
  'w65': (
    width: 65%
  ),
  'w70': (
    width: 70%
  ),
  'w75': (
    width: 75%
  ),
  'w80': (
    width: 80%
  ),
  'w85': (
    width: 85%
  ),
  'w90': (
    width: 90%
  ),
  'w95': (
    width: 95%
  ),
  'third': (
    width: calc(100% / 3)
  ),
  'two-thirds': (
    width: calc(100% * 2 / 3)
  ),
  'min-vw100': (
    min-width: 100vw
  ),
  'min-vh100': (
    min-height: 100vh
  ),
  'vw100': (
    width: 100vw
  ),
  'vh100': (
    height: 100vh
  ),
  'vh60': (
    height: 60vh
  ),
  'h20': (
    height: 20%
  ),
  'h25': (
    height: 25%
  ),
  'h50': (
    height: 50%
  ),
  'h75': (
    height: 75%
  )
);
